import { template as template_3351aa6527d74f1089572cbc9b25ec72 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3351aa6527d74f1089572cbc9b25ec72(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
