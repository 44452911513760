import { template as template_008c85f3872f4a3288cfda7d5c84eed3 } from "@ember/template-compiler";
const FKText = template_008c85f3872f4a3288cfda7d5c84eed3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
